<template>
	<div class="signature-pad">
		<div class="signature-pad-input">
			<div class="signature-pad-title">
				<div class="signature-pad-title-text"><i class="fas fa-signature"></i>&nbsp;SIGN</div>
			</div>
			<div style="position: relative;">
				<canvas :class="{ disabled: !signing }" id="signature_pad_canvas" width="560" height="180"></canvas>
			</div>
		</div>
		<div style="font-size: 0.66em; margin: 0.5em auto; text-align: right;">
			<div class="button neutral" @click="signatureAction = 'clear'" style="display: inline;">Clear Signature</div>
		</div>
	</div>
</template>

<script>
import SignaturePad from 'signature_pad'
export default {
	name: 'signature-inputs',
	props: {
		value: {
			type: [String],
			required: false
		},
		disabled: {
			type: [Boolean, String, Number],
			required: false,
			default: false
		},
		minor: {
			type: [Boolean, String, Number],
			required: false,
			default: false
		},
		doSave: {
			type: [Boolean, String, Number],
			required: false,
			default: false
		},
	},
	data() {
		return {
			loading: false,
			message: '',
			signaturePad: null,
			signing: false,
			scriptel: null,
			prevPoint: null,
			savedSignature: null,
			signatureAction: '',
			signOnScreen: '1',
			blankSignature: null
		}
	},
	created() {
	},
	mounted() {
		this.captureSignature()
	},
	watch: {
		doSave(newVal, oldVal) {
			if (newVal != oldVal) {
				this.saveSignature()
			}
		},
		signOnScreen(newVal, oldVal) {
			if (newVal != oldVal) {
				this.captureSignature()
			}
		},
		signatureAction(newVal, oldVal) {
			if (newVal != oldVal) {
				if (newVal == 'clear') {
					this.clearCanvas()
				}
				if (newVal == 'save') {
					this.saveSignature()
				}
			}
		}
	},
	methods: {
		captureSignature() {
			this.signing = true
			setTimeout(() => {
				this.initializeSignaturePad()
			}, 500)
		},
		initializeSignaturePad() {
			if (this.signOnScreen == '1') {
				let canvas = document.getElementById('signature_pad_canvas');
				this.signaturePad = new SignaturePad(canvas, { minWidth: 2, maxWidth: 2 })
				this.blankSignature = this.signaturePad.toDataURL()
			} else {
				//Get the canvas element from the document.
				this.signaturePad = document.getElementById('signature_pad_canvas');
				//Create a new ScriptelEasyScript handler
				this.scriptel = new ScriptelEasyScript();
				//If we're using international keyboard support this callback
				//looks for the starting character as typed by different keyboards
				//in an attempt to automatically detect which keyboard we're using.
				//
				//This is only provided as an example, depending on your use
				//case something more robust will probably be required.
				//
				//Note: This is only for non-streaming, non-compressed EasyScript
				//devices, in these modes this is handled automatically.
				this.scriptel.configurationCallback = (evt,chr) => {
					if(chr === '"') {
						this.scriptel.fromKeyboardSpec = "ca-fr";
					} else {
						this.scriptel.fromKeyboardSpec = "us-basic";
					}
				};
				//Adds a signature listener to the document that will automatically catch
				//any signatures "typed" within the document body.
				//
				//WARNING!!!!!
				//If you are creating pop-up window (modal) and
				//are registering the listener to document make sure you
				//call removeSignatureListener when the modal is
				//destroyed otherwise the library will continue listening.
				this.scriptel.addSignatureListener(document);
				//Adds a streaming callback to the EasyScript library,
				//this gets notified when events happen.
				this.scriptel.registerStreamingCallback((e) => {
					switch (e.type) {
						case "ScriptelSignatureMetaData":
							this.clearCanvas();
							break;
						case "ScriptelNewStroke":
							this.prevPoint = null;
							break;
						case "ScriptelCoordinate":
							if (this.prevPoint != null) {
								this.scriptel.drawSegmentOnCanvas(this.prevPoint, e, this.signaturePad,{"strokeStyle":"black","lineWidth":2});
							}
							this.prevPoint = e;
							break;
						case "ScriptelCancelSignature":
							this.clearCanvas();
							this.prevPoint = null;
							break;
						case "ScriptelSignatureComplete":
							this.prevPoint = null
							this.saveSignature();
							break;
					}
				})
			}
			this.clearCanvas()
		},
		cropCanvasImage(ctx) {
			var canvas = ctx.canvas, 
				w = canvas.width, h = canvas.height,
				pix = {x:[], y:[]},
				imageData = ctx.getImageData(0,0,canvas.width,canvas.height),
				x, y, index;
			for (y = 0; y < h; y++) {
				for (x = 0; x < w; x++) {
				index = (y * w + x) * 4;
				if (imageData.data[index+3] > 0) {
					pix.x.push(x);
					pix.y.push(y);
				} 
				}
			}
			pix.x.sort(function(a,b){return a-b});
			pix.y.sort(function(a,b){return a-b});
			var n = pix.x.length-1;
			w = 1 + pix.x[n] - pix.x[0];
			h = 1 + pix.y[n] - pix.y[0];
			var cut = ctx.getImageData(pix.x[0], pix.y[0], w, h);
			canvas.width = w;
			canvas.height = h;
			ctx.putImageData(cut, 0, 0);
		},
		saveSignature() {
			if (this.signOnScreen != '0' && this.signOnScreen != '1') { return }
			if (this.signaturePad.toDataURL() == this.blankSignature) { 
				this.$emit('input', null) // set parent value to null
				return
			}
			this.signing = false
			// emit the signature data to the parent
			//this.cropCanvasImage(document.getElementById("signature_pad_canvas").getContext("2d", { willReadFrequently: true }))
			this.savedSignature = this.signaturePad.toDataURL()
			this.$emit('input', this.savedSignature)
		},
		clearCanvas() {
			//if (this.signOnScreen != '0' && this.signOnScreen != '1') { return }
			this.savedSignature = null
			if (this.signOnScreen == '1') {
				this.signaturePad.clear()
			} else {
				//Get the graphics context of the canvas.
				var ctx = this.signaturePad.getContext("2d", { willReadFrequently: true });
				//Set the fill
				ctx.fillStyle = "transparent";
				//Clear the canvas and fill with the background color:
				ctx.clearRect(0,0,this.signaturePad.width,this.signaturePad.height);
			}
			document.getElementById("signature_pad_canvas").setAttribute('height', '180')
			document.getElementById("signature_pad_canvas").setAttribute('width', '560')
			this.signatureAction = ''
			this.signing = true
		}
	}
}
</script>
<style lang="scss" scoped>
.signature-pad {
	display: inline-block;
	.signature-pad-input {
		display: grid;
		grid-template-columns: 2.25em 1fr;
		border-bottom: 2px solid #4d4d4d;
		.signature-pad-title {
			height: 180px;
			background-color: #7b7b7b;
			clip-path: polygon(0 0, 100% 0%, 100% 78%, 50% 100%, 0 78%);
			position: relative;
			margin: 0 0 0.25em 0;
			.signature-pad-title-text {
				position: absolute;
				top: 50%;
				left: 50%;
				color: white;
				transform:  translateX(-50%) translateY(-50%) rotate(-90deg);
				letter-spacing: 0.2em;
				display: grid;
  				grid-auto-flow: column;
			}
		}
		canvas {
			border: none !important; border-radius: 0;
			padding: 0 1em;
		}
		canvas.disabled {
			background: none;
		}
	}
	.button {
		margin-right: 1em;
	}
}
</style>