<template>
<div>

<div v-if="waiverNew" class="waiver account-section-main">
	<h2>{{ waiverType || 'Waivers' }}</h2>

    <div v-if="loading" class="loading"><i class="fa fa-spinner fa-spin"/></div>
	
    <div v-else class="content">
        <div class="content-section">
            <div v-if="waiverType && waiverTypes.length > 1" class="pointer back-to-list-button" @click="waiverType = ''"><span class="material-symbols-outlined">&#xe5e0</span> Select a different waiver type</div>
        </div>

        <div v-if="!waiverType" class="content-section">
			<div v-if="!getUser.user_name">
				<p style="font-size: 1.33em;" v-if="contactWaiverId">Your waiver has been saved!</p>
				<p style="font-size: 1.33em;">You may complete {{ contactWaiverId ? 'another' : 'a' }} waiver as a guest, but we recommend you <router-link to="/myaccount/login"><span style="text-decoration: underline;">log in or create an account</span></router-link> to better manage your waivers.</p>
			</div>
			<div v-else-if="waivers.length > 0" class="pointer back-to-list-button" @click="waiverNew = ''"><span class="material-symbols-outlined">&#xe5e0</span> Back</div>
			<br>
            <h3>Select a Waiver Type...</h3>
            <div class="tiles">
                <div class="tile" v-for="waiverType in waiverTypes" @click="setWaiver(waiverType.waiver_type_id)">
                    <i class="fa fa-signature" />
                    <h3>{{ waiverType.description }}</h3>
                </div>
            </div>
        </div>

        <div v-if="waiverType" class="content-section">
			<div v-html="waiverDetail.content"></div>
		</div>

        <div v-if="waiverType && waiverDetail.minor" class="content-section" ref="minor_section">
            <h3>Minor's Info</h3>
            <div class="form-group column columns columns-2">
                <div class="form-pair">
                    <label-required label="Minor Name" :condition="minorInfo.full_name" />
                    <input type="text" v-model="minorInfo.full_name">
                </div>
                <div class="form-pair">
                    <label-required label="Date of Birth" :condition="minorInfo.birthdate" />
                    <input type="date" :max="waiverDate" v-model="minorInfo.birthdate">
                </div>
            </div>

            <div class="form-pair">
                <label-required label="Address" :condition="minorInfo.address_1" />
				<input type="text" v-model="minorInfo.address_1">
            </div>
            
            <div class="form-group column columns columns-2">
                <div class="form-pair">
                    <label-required label="City" :condition="minorInfo.city" />
                    <input type="text" v-model="minorInfo.city">
                </div>
                <div class="form-pair">
                    <label-required label="State" :condition="minorInfo.country != 'US' || minorInfo.state" />
                    <state-select type="text" v-model="minorInfo.state" :showProvinces="true" :showAPO="true" placeHolder="Select..."></state-select>
                </div>
                <div class="form-pair">
                    <label-required :label="minorInfo.country == 'US' ? 'Zip Code' : 'Postal Code'" :condition="minorInfo.zip_code" />
                    <input type="text" v-model="minorInfo.zip_code">
                </div>
                <div class="form-pair">
					<label-required label="Country" :condition="minorInfo.country" />
					<country-select v-model="minorInfo.country" :selectedCountry="minorInfo.country" placeHolder="Select..." />
                </div>

                <div class="button action" @click="saveMinorInfo()">Save Minor's Info</div>
				
				<div class="message account-message" :class="{ alert: error }" v-if="minorInfoMessage">
					<strong>
						<span class="material-symbols-outlined" v-if="error">&#xe645</span>
						<span class="material-symbols-outlined" v-else>&#xe5ca</span>
						<message :message="minorInfoMessage"></message>
					</strong>
				</div>
            </div>
            <div class="lock-section" v-if="lockSection('minor')"></div>
        </div>

        <div v-if="waiverType && !waiverDetail.minor" class="content-section" ref="contact_section">

            <h3>Your Info</h3>
            <div class="form-group column columns columns-2">
                <div class="form-pair">
					<label-required label="First Name" :condition="contactInfo.first_name" />
                    <input type="text" v-model="contactInfo.first_name">
                </div>
                <div class="form-pair">
                    <label>Middle Name</label>
                    <input type="text" v-model="contactInfo.middle_name">
                </div>
                <div class="form-pair">
					<label-required label="Last Name" :condition="contactInfo.last_name" />
                    <input type="text" v-model="contactInfo.last_name">
                </div>
                <div class="form-pair">
                    <label>Suffix</label>
                    <input type="text" v-model="contactInfo.suffix">
                </div>
            </div>
            
            <div class="form-pair">
                <label>Company</label>
                <input type="text" v-model="contactInfo.company_name">
            </div>
            <div class="form-pair">
				<label-required label="Address" :condition="contactInfo.address_1" />
                <input type="text" v-model="contactInfo.address_1">
            </div>

            <div class="form-group column columns columns-2">
                <div class="form-pair">
                    <label-required label="City" :condition="contactInfo.city" />
                    <input type="text" v-model="contactInfo.city">
                </div>
                <div class="form-pair">
                    <label-required label="State" :condition="contactInfo.state" />
                    <state-select v-model="contactInfo.state" :selectedState="contactInfo.state" :showProvinces="true" :showApo="true" placeHolder="Select..." />
                </div>
                <div class="form-pair">
                    <label-required :label="contactInfo.country == 'US' ? 'Zip Code' : 'Postal Code'" :condition="contactInfo.zip_code" />
                    <input type="text" v-model="contactInfo.zip_code">
                </div>
                <div class="form-pair">
                    <label-required label="Country" :condition="contactInfo.country" />
					<country-select v-model="contactInfo.country" :selectedCountry="contactInfo.country" placeHolder="Select..." />
                </div>
            
                <div class="form-pair">
                    <label-required label="Phone Number" :condition="contactInfo.country != 'US' || contactInfo.phone_number" />
                    <input type="tel" v-model="contactInfo.phone_number">
                </div>
                <div class="form-pair">
                    <label-required label="Email" :condition="contactInfo.email_address" />
                    <input type="email" v-model="contactInfo.email_address">
                </div>
            </div>
            <div class="form-group column columns columns-3">
                <div class="form-pair">
                    <label-required label="Date of Birth" :condition="contactInfo.birthdate" />
                    <input type="date" :max="waiverDate" v-model="contactInfo.birthdate">
                </div>
                <div class="form-pair">
                    <label-required label="Drivers License / ID Number" :condition="contactInfo.country != 'US' || contactInfo.drivers_license_number" />
                    <input type="text" v-model="contactInfo.drivers_license_number">
                </div>
                <div class="form-pair">
                    <label-required label="Issuing State" :condition="contactInfo.country != 'US' || contactInfo.drivers_license_state" />
                    <state-select type="text" v-model="contactInfo.drivers_license_state" :selectedState="contactInfo.drivers_license_state" :showProvinces="true" :showAPO="true" placeHolder="Select..."></state-select>
                </div>
            </div>

            <div class="form-group column columns columns-2">
                <div class="button action" @click="saveContactInfo()">Save Your Info</div>

				<div class="message account-message" :class="{ alert: error }" v-if="contactInfoMessage">
					<strong>
						<span class="material-symbols-outlined" v-if="error">&#xe645</span>
						<span class="material-symbols-outlined" v-else>&#xe5ca</span>
						<message :message="contactInfoMessage"></message>
					</strong>
				</div>
			</div>

            <div class="lock-section" v-if="lockSection('contact')"></div>

        </div>

        <div v-if="waiverType && waiverDetail.minor" class="content-section" ref="parent_guardian_section">
            <h3>Parent / Guardian Info</h3>
            <div class="form-group column columns columns-2">
                <div class="form-pair">
					<label-required label="First Name" :condition="contactInfo.first_name" />
                    <input type="text" v-model="contactInfo.first_name">
                </div>
                <div class="form-pair">
                    <label>Middle Name</label>
                    <input type="text" v-model="contactInfo.middle_name">
                </div>
                <div class="form-pair">
					<label-required label="Last Name" :condition="contactInfo.last_name" />
                    <input type="text" v-model="contactInfo.last_name">
                </div>
                <div class="form-pair">
                    <label>Suffix</label>
                    <input type="text" v-model="contactInfo.suffix">
                </div>
            </div>

            <div class="form-pair">
				<label-required label="Address" :condition="contactInfo.address_1" />
                <input type="text" v-model="contactInfo.address_1">
            </div>

            <div class="form-group column columns columns-2">
                <div class="form-pair">
					<label-required label="City" :condition="contactInfo.city" />
                    <input type="text" v-model="contactInfo.city">
                </div>
                <div class="form-pair">
					<label-required label="State" :condition="contactInfo.state" />
                    <state-select v-model="contactInfo.state" :selectedState="contactInfo.state" :showProvinces="true" :showApo="true" placeHolder="Select..."></state-select>
                </div>
                <div class="form-pair">
					<label-required label="Zip Code" :condition="contactInfo.zip_code" />
                    <input type="text" v-model="contactInfo.zip_code">
                </div>
                <div class="form-pair">
					<label-required label="Country" :condition="contactInfo.country" />
					<country-select v-model="contactInfo.country" :selectedCountry="contactInfo.country" placeHolder="Select..." />
                </div>
            </div>
            
            <div class="form-group column columns columns-2">
                <div class="form-pair">
					<label-required label="Phone Number" :condition="contactInfo.phone_number" />
                    <input type="tel" v-model="contactInfo.phone_number">
                </div>
                <div class="form-pair">
					<label-required label="Email" :condition="contactInfo.email_address" />
                    <input type="email" v-model="contactInfo.email_address">
                </div>
            </div>

            <div class="form-group column columns columns-3">
                <div class="form-pair">
					<label-required label="Date of Birth" :condition="contactInfo.birthdate" />
                    <input type="date" :max="waiverDate" v-model="contactInfo.birthdate">
                </div>
                <div class="form-pair">
                    <label-required label="Drivers License / ID Number" :condition="contactInfo.drivers_license_number" />
                    <input type="text" v-model="contactInfo.drivers_license_number">
                </div>
                <div class="form-pair">
                    <label-required label="Issuing State" :condition="contactInfo.drivers_license_state" />
                    <state-select type="text" v-model="contactInfo.drivers_license_state" :selectedState="contactInfo.drivers_license_state" :showProvinces="true" :showAPO="true" placeHolder="Select..."></state-select>
                </div>
            </div>

            <div style="margin: 0.5em 0 0 0;">
                <h4>Other authorized adult(s)</h4>
                <div class="form-group column columns columns-2">
                    <div class="form-pair">
                        <label>Full Name</label>
                        <input type="text" v-model="contactInfo.authorized_name_1">
                    </div>
                    <div class="form-pair">
                        <label>ID Type and Number</label>
                        <input type="text" v-model="contactInfo.authorized_id_1">
                    </div>
                    <div class="form-pair">
                        <label>Full Name</label>
                        <input type="text" v-model="contactInfo.authorized_name_2">
                    </div>
                    <div class="form-pair">
                        <label>ID Type and Number</label>
                        <input type="text" v-model="contactInfo.authorized_id_2">
                    </div>
                </div>

                <label>I hereby authorize the above listed adult(s) who are 21 years old or older to bring the above-referenced minor into {{ getClient.company_name }} to shoot firearms. They have been instructed that they must be with the minor at all times while the minor is in the shooting areas.</label>
            </div>

            <div class="form-group column columns columns-2">
                <div v-if="sectionsSaved['minor']" class="button neutral spaced" @click="editSection('minor')">Edit Minor's Info</div>
                <div class="button action" @click="saveContactInfo()">Save Parent / Guardian Info</div>

				<div class="message account-message" :class="{ alert: error }" v-if="contactInfoMessage">
					<strong>
						<span class="material-symbols-outlined" v-if="error">&#xe645</span>
						<span class="material-symbols-outlined" v-else>&#xe5ca</span>
						<message :message="contactInfoMessage"></message>
					</strong>
				</div>
            </div>

            <div class="lock-section" v-if="lockSection('contact')"></div>

        </div>

        <div v-if="waiverType" class="content-section" ref="emergency_section">
            <h3>Emergency Contact</h3>
            <div class="form-pair">
				<label-required label="Full Name" :condition="emergencyContactInfo.full_name" />
                <input type="text" v-model="emergencyContactInfo.full_name">
            </div>
            <div class="form-group column columns columns-2">
                <div class="form-pair">
					<label-required label="Phone Number" :condition="emergencyContactInfo.phone_number" />
                    <input type="tel" v-model="emergencyContactInfo.phone_number">
                </div>
                <div class="form-pair">
					<label-required :label="'Relationship to ' + (waiverDetail.minor ? 'the Minor' : 'You')" :condition="emergencyContactInfo.relationship" />
                    <input type="text" v-model="emergencyContactInfo.relationship">
                </div>

                <div v-if="sectionsSaved['contact']" class="button neutral spaced" @click="editSection('contact')">Edit {{ waiverDetail.minor ? 'Parent / Guardian ' : 'Your' }} Info</div>
                <div class="button action" @click="saveEmergencyContact()">Save Emergency Contact</div>

				<div class="message account-message" :class="{ alert: error }" v-if="emergencyInfoMessage">
					<strong>
						<span class="material-symbols-outlined" v-if="error">&#xe645</span>
						<span class="material-symbols-outlined" v-else>&#xe5ca</span>
						<message :message="emergencyInfoMessage"></message>
					</strong>
				</div>
            </div>
			
            <div class="lock-section" v-if="lockSection('emergency')"></div>
        </div>

        <div v-if="waiverType" class="content-section" ref="waiver_section">

            <!-- <div v-html="waiverDetail.content"></div> -->

            <h3>{{ waiverDetail.minor ? 'Parent / Guardian ' : '' }}Draw Your Signature</h3>
            <div v-if="waiverDetail.minor" class="message account-message" style="margin: 0 0 1em 0;">
                I am the natural, adoptive parent, or legal guardian of the minor child (under 18 years of age), whose name and date of birth are shown above. I understand and acknowledge that this authorization form shall be on file with {{ getClient.company_name }} and shall be effective as long as the minor child is a minor (under 18 years of age) or until my written request to terminate this Authorization.
            </div>
            <signature-inputs v-model="waiverDetail.signature" :doSave="saving" :minor="waiverDetail.minor" />
            <div class="message account-message" style="margin: 1em 0 0 0;">
                By signing here, you are consenting to the use of your electronic signature in lieu of an original signature on paper. You have the right to request that you sign a paper copy instead. Your agreement to use an electronic signature with us for any documents will continue until such time as you notify us in writing that you no longer wish to use an electronic signature. Please make sure that we have your current email address in case we need to contact you regarding any changes to this waiver.
            </div>
            <div class="divider"></div>
            
			<div class="form-group column columns columns-2" style="align-items:center;">
                <div class="form-pair">
                    <label-required label="Date Signed" :condition="waiverDate" />
                    <input type="date" v-model="waiverDate" style="width: 10em;" />
                </div>

            </div>

            <div class="form-group column columns column-2" style="margin: 0.5em 0 3em 0;">
                <div v-if="sectionsSaved['emergency']" class="button neutral spaced" @click="editSection('emergency')">Edit Emergency Contact</div>
                <div class="button action" @click="saveWaiver()">Save This Waiver</div>
            </div>
			
			<div class="message account-message" :class="{ alert: error }" v-if="saveMessage">
				<strong>
					<span class="material-symbols-outlined" v-if="error">&#xe645</span>
					<span class="material-symbols-outlined" v-else>&#xe5ca</span>
					<message :message="saveMessage"></message>
				</strong>
			</div>

            <div class="lock-section" v-if="lockSection('waiver')"></div>

        </div>

    </div>

    <!-- <message-modal v-if="message" :message="message" :loading="saving" :errorStatus="messageStatus" :timeout="messageTimeout" @dismiss="message = ''" /> -->

</div>

<div v-else-if="!showWaiverDetail.waiver_id" class="waiver account-section-main">
	<h2>Your Waivers</h2>
	<div class="loading full-page" v-if="loading"><i class="fas fa-spinner fa-spin"></i></div>
	<div>
		<div v-if="customerWaivers.length > 0" class="waivers-list">
			<div v-for="waiver in customerWaivers" class="three-columns clickable-list-item" @click="showWaiver(waiver)">
				<div>
					<label>Waiver Date</label><br>
					{{ $formatDate(waiver.waiver_date) }}
				</div>
				<div>
					<label>Waiver Description</label><br>
					{{ waiver.description }}
				</div>
				<div>
					<label>Waiver Type</label><br>
					{{ waiver.waiver_type_code }}
				</div>
			</div>
		</div>
		
		<div v-else class="no-results">
			<h3>You don't have any waivers on file.</h3>
		</div>

		<div class="controls" style="margin: 1em 0 0 0;">
			<button class="new-button action button" @click="waiverNew = true">Start a new waiver</button>
		</div>

	</div>

</div>

<div v-if="showWaiverDetail.waiver_id" :showWaiverDetail="showWaiverDetail" class="waiver account-section-main">
	<div class="controls">
		<div v-if="waivers.length > 0" class="button action" @click="showWaiverDetail = {}"><span class="material-symbols-outlined">&#xe5e0</span> Back to Waivers</div>
	</div>
	<div class="content" ref="waiver_content">
		<h1>{{ showWaiverDetail.description }}</h1>

		<div v-if="showWaiverDetail.minor" style="margin: 0 0 1em 0;">
			<h3>Minor Info</h3>
			{{ showWaiverDetail.minor_info.full_name }}<br>
			{{ showWaiverDetail.minor_info.address_1 }}<br>
			{{ showWaiverDetail.minor_info.city }} {{ showWaiverDetail.minor_info.state }} {{ showWaiverDetail.minor_info.zip_code }} {{ showWaiverDetail.minor_info.country }}<br>
			<label>Date of Birth</label> {{ $formatDate(showWaiverDetail.minor_info.birthdate) }}
		</div>

		<div style="margin: 0 0 1em 0;">
			<h3>{{ showWaiverDetail.minor ? 'Parent / Guardian' : 'Customer' }} Info</h3>
			<table style="width: 100%;">
				<tr>
					<td>
						{{ showWaiverDetail.contact_info.first_name }} {{ showWaiverDetail.contact_info.last_name }}<br>
						{{ showWaiverDetail.contact_info.address_1 }}<br>
						{{ showWaiverDetail.contact_info.city }} {{ showWaiverDetail.contact_info.state }} {{ showWaiverDetail.contact_info.zip_code }}<br>
					</td>
					<td>
						<label>Date of Birth</label> {{ $formatDate(showWaiverDetail.contact_info.birthdate) }}<br>
						<label>Phone</label> {{ showWaiverDetail.contact_info.phone_number }}<br>
						<label>Email</label> {{ showWaiverDetail.contact_info.email_address }}<br>
						<label>Drivers License / ID</label> {{ showWaiverDetail.contact_info.drivers_license_state }} {{ showWaiverDetail.contact_info.drivers_license_number }}
					</td>
				</tr>
			</table>
		</div>

		<div style="margin: 0 0 1em 0;">
			<h3>Emergency Contact</h3>
			{{ showWaiverDetail.emergency_contact_info.full_name }}<br>
			<label>Phone</label> {{ showWaiverDetail.emergency_contact_info.phone_number }}<br>
			<label>Relationship</label> {{ showWaiverDetail.emergency_contact_info.relationship }}
		</div>

		<h3>Waiver</h3>
		<div v-html="showWaiverDetail.content"></div>
		<img v-if="showWaiverDetail.signature" :src="showWaiverDetail.signature" />
		<div v-else style="font-family:'Brush Script MT',cursive; font-size: 3em;">{{ showWaiverDetail.contact_info.first_name }} {{ showWaiverDetail.contact_info.last_name }}</div>
		<div><label>Date Signed</label> {{ $formatDate(showWaiverDetail.waiver_date) }}</div>
	</div><br>
	<div class="controls">
		<div v-if="waivers.length > 0" class="button action" @click="showWaiverDetail = {}"><span class="material-symbols-outlined">&#xe5e0</span> Back to Waivers</div>
	</div>
</div>

</div>
</template>

<script>
import StateSelect from '@/components/StateSelect.vue'
import CountrySelect from '@/components/CountrySelect.vue'
import MessageModal from '@/components/MessageModal.vue'
import LabelRequired from '@/components/LabelRequired.vue'
import SignatureInputs from '@/components/SignatureInputs.vue'
export default {
	name: 'Waiver',
	components: {
		'state-select': StateSelect,
		'country-select': CountrySelect,
		'message-modal': MessageModal,
		'label-required': LabelRequired,
		'signature-inputs': SignatureInputs
	},
	data() {
		return {
			loading: false,
			waivers: [],
			customerWaivers: [],
			showWaiverDetail: {},
			waiverNew: '',
			message: '',
			messageStatus: '',
			messageTimeout: 0,
			error: false,
			contactInfoMessage: null,
			minorInfoMessage: null,
			emergencyInfoMessage: null,
			waiversMessage: '',
			saveMessage: '',
			contactInfo: {
				first_name: '',
				last_name: '',
				email_address: '',
				company_name: '',
				address_1: '',
				address_2: '',
				city: '',
				state: '',
				zip_code: '',
				country_code: '',
				phone_number: '',
				country: 'US',
				drivers_license_number: '',
				drivers_license_state: ''
			},
			minorInfo: {
				full_name: '',
				address_1: '',
				city: '',
				state: '',
				zip_code: '',
				birthdate: '',
				country: 'US'
			},
			contactInfoConfirmed: false,
			emergencyContactInfo: {
				full_name: '',
				phone_number: '',
				relationship: ''
			},
			waiverTypes: [],
			waiverType: '',
			waiverDetail: {},
			signaturePad: null,
			blankSignature: null,
			waiverSignature: null,
			showSignatureClear: false,
			waiverDate: '',
			saving: false,
			sectionsSaved: {
				minor: false,
				contact: false,
				emergency: false,
				waiver: false
			},
			contactWaiverId: ''
		}
	},
	created() {
		if (this.getUser.user_id) {
			this.getContact()
		} else {
			this.getWaivers()
			this.waiverNew = true
		}
		this.waiverDate = new Date().toISOString().slice(0,10)
	},
	methods: {
		convertOembedToIFrame() {
			setTimeout(() => {
				let media = document.querySelectorAll('figure.media oembed')
				media.forEach(async element => {
					let url = element.getAttribute('url')
					let api = ''
					if (url.indexOf('vimeo.com') > -1) {
						api = 'https://vimeo.com/api/oembed.json?url='
					} else {
						api = 'https://www.youtube.com/oembed?url='
					}
					await fetch(api + url).then(response => {
						return response.json()
					}).then(obj => {
						let iframe = new DOMParser().parseFromString(obj.html, "text/html").body.firstElementChild
						let ratio = obj.width / obj.height
						iframe.width = 640
						iframe.height = 640 / ratio
						element.parentNode.replaceChild(iframe, element)
					})
				});
			}, 500)
		},
		getContact() {
			this.apiCall({ destination: 'get_item_contact', data: { contact_id: this.getUser.contact_id } }).then(obj => {
				if (obj.status == 'success') {
					this.contactInfo = obj.contact
					if (this.contactInfo.addresses) {
						// get address where address_type_code = 'EMERGENCY'
						for (let i = 0; i < this.contactInfo.addresses.length; i++) {
							if (this.contactInfo.addresses[i].address_type_code == 'EMERGENCY') {
								this.emergencyContactInfo = this.contactInfo.addresses[i]
								this.emergencyContactInfo.relationship = this.contactInfo.addresses[i].address_label
								break
							}
						}
					}
					this.getWaivers()
				} else {
					this.message = obj.message
				}
			}).catch(error => {
				console.log(error)
			})
		},
		getWaivers() {
			if (this.loading) { return }
			this.loading = true
			this.message = ''
			this.apiCall({ destination: 'get_list_waivers', data: { contact_id: this.contactInfo.contact_id } }).then((obj) => {
				this.loading = false
				this.message = obj.message
				if (obj.status == 'success') {
					this.customerWaivers = obj.waivers
					this.waiverTypes = obj.waiver_types
					this.waivers = obj.list
					if (this.waiverTypes.length == 1) {
						this.waiverType = this.waiverTypes[0]['description']
						this.waiverDetail = this.waivers[0]
						this.convertOembedToIFrame()
					}
				}
			})
		},
		showWaiver(waiver) {
			if (waiver.contact_waiver_id) {
				this.showWaiverDetail = waiver
				this.showAttachments = false
			} else {
				this.apiCall({ destination: 'get_item_application_pdf', data: { contact_id: waiver.contact_id } }).then(obj => {
				    if (obj.status == 'success' && obj.pdf_url) {
						// open pdf_url in a new window
						window.open(obj.pdf_url, '_blank')
				    }
				})
			}
		},
		setWaiver(waiverTypeId) {
			for (let i = 0; i < this.waiverTypes.length; i++) {
				if (this.waiverTypes[i]['waiver_type_id'] == waiverTypeId) {
					this.waiverType = this.waiverTypes[i]['description']
					break
				}
			}
			for (let i = 0; i < this.waivers.length; i++) {
				if (this.waivers[i]['waiver_type_id'] == waiverTypeId) {
					this.waiverDetail = this.waivers[i]
					break
				}
			}
			this.convertOembedToIFrame()
		},
		lockSection(section) {
			switch (section) {
				case 'minor':
					return this.sectionsSaved['minor']
				case 'contact':
					if (this.waiverDetail.minor) {
						return this.sectionsSaved['contact'] || !this.sectionsSaved['minor']
					} else {
						return this.sectionsSaved['contact']
					}
				case 'emergency':
					return this.sectionsSaved['emergency'] || !this.sectionsSaved['contact']
				case 'waiver':
					return this.sectionsSaved['waiver'] || !this.sectionsSaved['emergency']
			}
		},
		editSection(section) {
			this.sectionsSaved[section] = false
			if (this.waiverDetail.minor && section == 'contact') {
				section = 'parent_guardian'
			}
			this.$refs[section + '_section'].scrollIntoView({ behavior: 'smooth', block: 'start' })
		},
		saveMinorInfo() {
			this.error = false
			this.minorInfoMessage = null
			if (this.saving) { return }
			this.minorInfoMessage = ""
			let requiredFields = ['full_name', 'address_1', 'city', 'zip_code', 'birthdate']
			if (this.minorInfo.country == 'US') {
				requiredFields.push('state')
			}
			for (let i = 0; i < requiredFields.length; i++) {
				if (!this.minorInfo[requiredFields[i]]) {
					console.log(requiredFields[i])
					this.minorInfoMessage = "Please enter minor's info for all required fields."
					this.messageStatus = 'invalid'
					this.error = true
					return
				}
			}
			this.sectionsSaved['minor'] = true
			this.$refs.parent_guardian_section.scrollIntoView({ behavior: 'smooth', block: 'start' })
		},
		saveContactInfo() {
			this.error = false
			this.contactInfoMessage = null
			if (this.saving) { return }
			let requiredFields = ['first_name', 'last_name', 'address_1', 'city', 'zip_code', 'birthdate']
			if (this.contactInfo.country == 'US') {
				requiredFields.push('state')
			}
			if (!this.waiverDetail.minor) {
				requiredFields.push('phone_number', 'email_address')
				if (this.contactInfo.country == 'US') {
					requiredFields.push('drivers_license_number', 'drivers_license_state')
				}
			}
			for (let i = 0; i < requiredFields.length; i++) {
				if (!this.contactInfo[requiredFields[i]]) {
					this.contactInfoMessage = 'Please enter your info for all required fields.'
					this.messageStatus = 'invalid'
					this.error = true
					return
				}
			}
			this.sectionsSaved['contact'] = true
			this.$refs.emergency_section.scrollIntoView({ behavior: 'smooth', block: 'start' })
		},
		saveEmergencyContact() {
			this.error = false
			this.emergencyInfoMessage = null
			if (this.saving) { return }
			this.emergencyInfoMessage = ""
			let requiredFields = ['full_name', 'phone_number', 'relationship']
			for (let i = 0; i < requiredFields.length; i++) {
				if (!this.emergencyContactInfo[requiredFields[i]]) {
					this.emergencyInfoMessage = "Please enter Emergency Contact info for all required fields."
					this.messageStatus = 'invalid'
					this.error = true
					return
				}
			}
			this.sectionsSaved['emergency'] = true
			this.$refs.waiver_section.scrollIntoView({ behavior: 'smooth', block: 'start' })
		},
		saveWaiver() {
			if (this.saving) { return }
			this.saving = true
			this.error = false
			this.saveMessage = 'Checking waiver...'
			// timeout allows signature to update
			setTimeout(() => {
				// is there a signature?
				if (!this.waiverDetail.signature) {
					this.saveMessage = 'Please sign this waiver.'
					this.messageStatus = 'invalid'
					this.saving = false
					this.error = true
					return
				}
				// how about a date?
				if (!this.waiverDate) {
					this.saveMessage = 'Please enter a Date Signed.'
					this.messageStatus = 'invalid'
					this.saving = false
					this.error = true
					return
				}
				// is the date in the future?
				let today = new Date()
				let waiverDate = new Date(this.waiverDate)
				if (waiverDate > today) {
					this.saveMessage = 'Future Date Signed is not allowed.'
					this.messageStatus = 'invalid'
					this.saving = false
					this.error = true
					return
				}

				this.sectionsSaved['waiver'] = true

				let postData = {
					waiver_id: this.waiverDetail.waiver_id,
					waiver_date: this.waiverDate,
					signature: this.waiverDetail.signature,
					contact_info: this.contactInfo,
					emergency_contact_info: this.emergencyContactInfo
				}
				if (this.waiverDetail.minor) {
					postData.minor_info = this.minorInfo
				}
				this.apiCall({ destination: 'save_item_contact_waiver', data: postData }).then((obj) => {
					this.saving = false
					this.error = false
					this.messageStatus = obj.status
					if (obj.status == 'success') {
						this.saveMessage = 'Waiver saved!'
						this.contactWaiverId = obj.contact_waiver_id
						setTimeout(() => {
							if (this.getUser.user_id) {
								this.waiverNew = false
								this.getWaivers()
							} else {
								this.waiverType = ''
							}
						}, 1500)
					} else {
						this.saveMessage = obj.message
						this.sectionsSaved['waiver'] = false
					}
				})
				this.saving = false
			}, 500)
		}
	}
}
</script>

<style lang="scss" scoped>

.tiles{
    margin: 2em;
	padding: 0 0 4em 0;
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 2em;
	align-items: center;
	.tile {
		border: 1px solid gray;
		border-radius: 0.66em;
		padding: 2em 1em;
		background-color: #ffffff;
		text-align: center;
		.fa, .fas {
			font-size: 3em;
			padding: 0 0 0.66em 0;
			color: gray;
		}
	}
}

.back-to-list-button {
    color: #000;
    .material-symbols-outlined { font-size: 1em; }
}
.message {
    grid-template-columns: 1fr;
}
.message strong {
	background-color: none;
	padding: 0;
}
.content-section {
    margin-bottom: 1.5em;
}

.waivers-list {
	margin-top: 1em;
	.clickable-list-item {
		padding: 0.5em;
	}
}

.content-section {
	position: relative;
}
.lock-section {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(255, 255, 255, 0.6);
	text-align: center;
	cursor: not-allowed;
	font-size: 2em;
	color: #ccc;
}


@media (max-width: 920px) {
    .columns-3, .message {
        grid-template-columns: 1fr 1fr;
    }
    .form-pair {
		select { width: 100%; }
		input { width: 93.5%; }
	}
    .button {
        grid-column: 1/-1;
    }
    .content-section {
        p {
            iframe {
                width: 100%;
            }
        }
    }
}
@media (max-width: 768px) {
    .columns-2, .columns-3, .form-group {
        grid-template-columns: 1fr;
    }
    .tiles {
        grid-template-columns: 1fr;
    }
}
</style>