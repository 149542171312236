<template>
    <div class="modal">
        <div class="inner modal-message">
            <h2>{{ showHeading() }}</h2>
            <div class="content modal-message-content">
                <div v-html="message"></div>
                <div v-if="loading" class="modal-icon"><i class="fa fa-spinner fa-spin" /></div>
                <div v-else class="modal-icon"><i :class="showIcon()" /></div>
            </div>
            <div class="controls control-buttons modal-message-controls">
                <div :class="['button', { spaced : errorStatus == 'delete' }, loading ? 'disabled' : 'cancel']" @click="dismissMessage()">Dismiss</div>
                <div v-if="errorStatus == 'delete'" class="button delete" @click="doAction()">Yes, Delete</div>
            </div>
        </div>
    </div>
</template>
<script>
// https://fontawesome.com/search?p=3&s=regular
export default {
    name: 'message',
    props: {
        message: {
            type: String,
            default: ''
        },
        loading: {
            type: [String, Boolean],
            default: false
        },
        errorStatus: {
            type: [String, Boolean],
            default: false
        },
        timeout: {
            type: [String, Number],
            default: 0
        }
    },
	data() {
		return {
			messageTimeout: null
		}
	},
    created() {
        if (this.timeout > 0) {
            this.setMessageTimeout()
        }
    },
    watch: {
		'timeout' (newVal, oldVal) {
            if (this.timeout > 0) {
                this.setMessageTimeout()
            }
		}
	},
	methods: {
        setMessageTimeout() {
            this.messageTimeout = setTimeout(() => { this.dismissMessage() }, 3000)
        },
        dismissMessage() {
            if (!this.loading) {
                clearTimeout(this.messageTimeout)
                this.$emit('dismiss')
            }
        },
        showHeading() {
            if (this.loading) {
                return 'Please Wait'
            }
            switch (this.errorStatus) {
                case 'invalid':
                    return 'Please Correct'
                case 'waiting':
                    return 'Please Wait'
                case 'alert':
                    return 'Alert'
                case 'delete':
                    return 'Confirm'
                case 'error':
                    return 'Oops!'
                default:
                    return 'Success!'
            }
        },
        showIcon() {
            switch (this.errorStatus) {
                case 'invalid':
                    return 'fa fa-ban'
                case 'waiting':
                    return 'fa fa-hourglass'
                case 'alert':
                    return 'fa fa-bell'
                case 'error':
                    return 'fa fa-bug';
                case 'delete':
                    return 'fa fa-trash'
                default:
                    return 'fa fa-check'
            }
        },
        doAction() {
            this.$emit('action')
        }
    }
}
</script>
